import '../../css/modules/_select.scss';
import {submitData} from './livedata';

$(document).ready(function () {

	// 1. wstępne ustawienie list i przycisków po załadowaniu widoku przyciskiem
	
	// jeśli ładowany widok po resfreshu zawiera .toggle-edit-mode, a nie dopiero jest ładowany buttonem
	var $btnToggle = $('body').find('.toggle-edit-mode'),
		areaName = $btnToggle !== undefined ? $btnToggle.data('target') : undefined,
		$area = areaName !== undefined ? $('body').find(areaName) : undefined;
	console.log('$btnToggle', $btnToggle !== undefined ? 'btn.toggle-edit-mode target: ' + $btnToggle.data('target') : 'btn.toggle-edit-mode not exists');
	console.log('areaName', areaName);		
	console.log('$area', $area !== undefined ? $area.prop('id') : 'undefined');	
	if($area !== undefined){
		setEditing($area);
	}
	
	console.log('$("body")',$('body'));
	// jeśli widok jest ładowany buttonem
	$('body').on('click', '.toggle-edit-mode', function(){
		console.log('$area',$area);
		if($area === undefined){
			areaName = $(this).data('target');
			$area = $(areaName);
			if ($area !== undefined){
				setEditing($area);
			} else if(areaName !== undefined) {
				// dla widoków ładowaanych z opóźnieniem przyciskiem .toggle-edit-mode
				setTimeout(function(){
					$area = $('body').find(areaName);
					setEditing($area);
				}, 3000	);
			} 
		}
		$(this).blur();
	});

	
	// ustawienie list i przycisków w trybie edycji (stare, możliwe błędy)
//	$('body').on('click', '.toggle-edit-mode', function(){
//		var boxName = $(this).data('target');
//		var box = $(boxName);
//		if(box !== undefined){
//			var $selects = box.find('.select-from-list .custom-select');
//			console.log('$selects', $selects);
//			$selects.each(function(){
//				resetSelect($(this));
//			});		
//		}
//		$(this).blur();
//	});

//	var $area = $($('body .toggle-edit-mode').data('target'));
//	console.log('$area', $area.prop('id'));
	
	// 2. przełączenie przycisków listy po wybraniu pozycji na liście 
	$('body').on('change', '.select-from-list .custom-select', function(){
		console.log('przełącz listę', $(this).data());
		setListOptions($(this), $(this).prop('name'));
		// kolory dla labela
		if($(this).hasClass('label-select')){
			var d = $(this).find('option:selected').data();
			var $txt = $(this).closest('.input-group').parent().find('input[name="txt"]'),
				$bkg = $(this).closest('.input-group').parent().find('input[name="bkg"]');
			console.log('selectedData', d);
			if (d !== undefined && d.color !== undefined){
				$(this).css({'color':d.color, 'background-color':d.background, 'border-color':d.background, 'font-weight':'bold'});
				$txt.data('id',$(this).val());
				$bkg.data('id',$(this).val());
				$txt.val(d.color);
				$bkg.val(d.background);
			} else {
				$(this).css({'color':'#949494', 'background-color':'#f8f8f8', 'border-color':'#f8f8f8', 'font-weight':'normal'});
				$txt.data('id',0);
				$bkg.data('id',0);
				$txt.val('#949494');
				$bkg.val('#f8f8f8');
			}
		}
		$(this).blur();
	});

	// 3. przycisk dodania pozycji do listy - przełaczenie na widok dodawania
	$('body').on('click', '.btn-to-list', function(){
		console.log('dodawanie do listy');
		// blok listy do wymiany na zaktualizowaną listę po edycji
		var $block = $(this).closest('.group-select-add');
		var $select = $block.find('select');
		var parentVal = valueFromParent($select);
		$(this).blur();
//		console.log('wartość zależna', sdVal);
		// jeśli nie ma parenta lub jest oraz ID parenta > 0, pokazujemy pole edycji zamiast listy
		if(parentVal === undefined || parentVal > 0){
			showEditList('new', $block, $(this).data('action'));
		}
	});

	// 3. przycisk usunięcia pozycji z listy - przełączenie na widok usuwania
	$('body').on('click', '.btn-remove-list', function(){
		var $block = $(this).closest('.group-select-add');
		var action = $(this).data('action');
		$(this).blur();
		var $select = $block.find('select');
		var id = parseInt($select.val(),10) ? parseInt($select.val(),10) : 0;
		var $inputRemove = $block.find('.add-to-list input.form-control-plaintext');
		console.log('$inputRemove', $inputRemove);
		if(id) {
			var value = $inputRemove.data('remove') + ' ' + $select.find('option:selected').text().trim() + '?';
			showEditList('remove', $block, action, id, value, $inputRemove);
		}
	});

	// 3. lista: przycisk edycji pozycji listy - przełączenie na widok edycji
	$('body').on('click', '.btn-edit-list', function(){
		var $block = $(this).closest('.group-select-add');
		var action = $(this).data('action');
		var $select = $block.find('select');
		var id = parseInt($select.val(),10) ? parseInt($select.val(),10) : 0;
		if(id) {
			var value = $select.find('option:selected').text().trim();
			showEditList('edit', $block, action, id, value);
		}
		$(this).blur();
	});
	// =============== widok edycji/dodawania/kasowania =============== //
	// 4. przycisk rezygnacji - ukrycie inputa, pokazanie listy
	$('body').on('click', '.btn-cancel-to-list', function(){
		hideEditList($(this).closest('.group-select-add'));
		$(this).blur();
	});
	// 4. przycisk akcji - ukrycie inputa, pokazanie listy, post do kontrolera
	$('body').on('click', '.btn-action-list', function(){
		execEditList($(this));
		toggleListButtons($(this).closest('.add-to-list').find('select'));
		$(this).blur();
	});
	// 4. przycisk enter na edycji - ukrycie inputa, pokazanie listy, post do kontrolera
	$('body').on('keyup', '.input-list-edit', function(e){
		var key = e.which;
		if(key === 13){
			var $button = $(this).closest('.add-to-list').find('.btn-action-list');
			execEditList($button);
			toggleListButtons($(this).closest('.add-to-list').find('select'));
			$(this).blur();
		}
	});
	
	// inne akcje
	$('body').on('click', '.btn-module-action', function(){
		var $btn = $(this),
			allData = $btn.data(),
			action = allData.action;
		console.log('action',action);
		var	$input = $btn.closest(action.data.parent).find(action.data.src),
			id = $input.data('id'),
			value = $input.val(),
			data = {id:id, value:value},
			$fillBlock = $btn.closest('.group-select-add');
		console.log('action',action);
		console.log('data',action);
		switch(action.type){ // rozbudować
			case 'post': submitData(data, action.url, $fillBlock); break;
		}
	});
});

// 4. akcja po kliknięciu zapisu lub naciśnięciu enter w polu edycji
function execEditList($button){
	var $block = $button.closest('.group-select-add'),
		action = $button.data('action'),
		$select = $block.find('select'),
		type = $button.data('type'),
		childNames = $select.data('child-names'),
		execData = {
		'id': type !== 'new' ? parseInt($select.val(),10) : 0, //parseInt($button.data('id'), 10) : 0,
		'value': $block.find('.add-to-list input.form-control').val().trim(),
		'parent_id': valueFromParent($select)
	};
	hideEditList($block);
	submitData(execData, action, $block);
	if((type === 'new' || type === 'remove') && childNames !== undefined && childNames.length) {
		var $form = $select.closest('form');
		$.each(childNames, function(idx, name){
			resetSelect($form.find('.group-select-add.' + name + ' select'));
		});
		
	}
}

// 1+2. lista: wyświetlenie przycisków edycji i usuwania
function toggleListButtons($select){
//	console.log(
//		'Ustawiam przyciski ' + $select.prop('name') + ' val: ', 
//		parseInt($select.val(),10) ? parseInt($select.val(),10) : $select.val()
//	);
	var $block = $select.parents('.group-select-add');
	setButtonsEditRemove($block.find('.btn-outline-secondary.btn-edit-list'), $select);
	setButtonsEditRemove($block.find('.btn-outline-secondary.btn-remove-list'), $select);
	setButtonNewStatus($block.find('.btn-outline-secondary.btn-to-list'), $select);
}

/*
 * Przyciski edycji i usuwania z listy mogą być widoczne, jeśli jest wybrana jakaś wartość na liście
 * @param {object} $button
 * @param {object} $select
 */
function setButtonsEditRemove($button, $select){
	var style = 'none';
	if (parseInt($select.val(),10)) {
		style = 'inline-block';
	}
	$button.css('display', style);
}

/*
 * Przycisk dodawania do listy ma być dostpny dla list samodzielnych, parent
 * oraz child, ale wtedy tylko, jeśli parentowa lista ma wybraną wartość
 */
function setButtonNewStatus($new, $select){
	var parentValue = valueFromParent($select),
		childNames = $select.data('child-names');

	if(parentValue !== undefined){
		// istnieje parentowa lista
		$new.prop('disabled', parentValue > 0 ? false : true);
	}else{
		// brak parentowej listy
		$new.prop('disabled', false);
	}
	
	if(childNames !== undefined && childNames.length){
		var $form = $select.closest('form');
		$.each(childNames, function(idx, name){
			var $childSelect = $form.find(name + '_id'),
				$btn = $childSelect.closest('.group-select-add').find('.btn-outline-secondary.btn-to-list');
			setButtonNewStatus($btn, $childSelect);
		});
	}
}

// 3. lista: ustawienie danych i styli oraz przełączenie na blok edycji
function showEditList($type, $block, action, id=0, value='', remove=false){
	console.log({'action': action, 'id': id, 'value': value, 'remove': remove});
	var $btnAction = $block.find('.add-to-list .btn-action-list');
	$btnAction.data('action', action); 
	$btnAction.data('id', id);
	$btnAction.data('type', $type);
	if (!remove) {
		var $input = $block.find('.add-to-list input.form-control');
	}else{
		var $input = $block.find('.add-to-list input.form-control-plaintext');
	}
	$input.css('display', 'inline-block');
	$input.val(value);
	$block.find('.select-from-list').css('display', 'none');
	$block.find('.add-to-list').css('display', 'flex');
}

// 4. lista: przełączenie bloku edycji na widok bloku listy
function hideEditList($block) {
	var $btnAction = $block.find('.add-to-list .btn-action-list');
	$btnAction.data('action', ''); 
	$btnAction.data('id', 0);
	$btnAction.data('type', '');
	var $inputRemove = $block.find('.add-to-list input.form-control-plaintext');
	$inputRemove.val('');
	$inputRemove.css('display', 'none');
	var $input = $block.find('.add-to-list input.form-control');
	$input.val('');
	$input.css('display', 'none');
	$block.find('.add-to-list').css('display', 'none');
	$block.find('.select-from-list').css('display', 'flex');
}

/*
 * wyświetla / zmienia listę po edycji/usuwaniu/dodawaniu
 * wyświetla / zmienia listę podrzędną po zmianie opcji selecta
 * 
 * @param {object} $select
 */
function setListOptions($select, listWithoutName=''){
	
	var id = parseInt($select.val(), 10) ? parseInt($select.val(), 10) : 0,
		childNames = $select.data('childNames'),
		doList = $select.prop('name') !== listWithoutName,
		parentId = valueFromParent($select),
		action = $select.data('listUrl'),
		$fillBlock = $select.closest('.group-select-add');

	console.log($select.prop('name'),$select.val(), ' parent value ' + $select.data('parentName') + '_id:', parentId, ' Submit self:', doList);
	if($select.val == 0){
		$select.prop('selectedIndex', 0);
	}
	if(action !== undefined && $fillBlock !== undefined){
		if(parentId !== undefined){ // jest
			if(!parentId){ // nie wybrano żadnej opcji
				console.log('Brak parent_id');
				resetSelect($select);
			}else if (doList){ // opcja selecta jest wybrana lub input hidden ustawiony
				// to nie jest bieżący select po zmianie pozycji
				console.log('submit', {id: id, parent_id: parentId}, action, $fillBlock);
				submitData({id: id, parent_id: parentId}, action, $fillBlock);
				$fillBlock.css('display', 'block');
//				$select.prop('selectedIndex', 0);
			}
		}else{
			submitData({id: id}, action, $fillBlock);
		}
	}
	toggleListButtons($select);
	
	if(childNames !== undefined && childNames.length){
		console.log('Listy zależne', childNames);
		var $form = $select.closest('form');
		$.each(childNames, function(idx, name){
			var $child = $form.find('select[name="' + name + '_id"]');
			console.log('Lista podrzędna ' + name + '_id', $child);
			setListOptions($child, listWithoutName);
		});
	}
}

function resetSelect($select){
	$select.prop('selectedIndex', 0);
	var $block = $select.closest('.group-select-add'),
		parentId = valueFromParent($select),
		parentType= typeParent($select);
	$block.find('.btn-outline-secondary.btn-edit-list').css('display', 'none');
	$block.find('.btn-outline-secondary.btn-remove-list').css('display', 'none');
	if(parentId !== undefined && parentType !== undefined && parentType === 'select' && parentId === 0){
		// istnieje nadrzędny select, ale nie ma wybranej opcji, 
		// wtedy wyłączamy przycisk dodawania do listy oraz ukrywamy tą listę
		$block.find('.btn-outline-secondary.btn-to-list').prop('disabled', true);
		$block.css('display', 'none');
	}else{
		// jeśli to lista nadrzędna I stopnia lub parent ma wybraną opcję, 
		// wyświetlamy tą listę oraz aktywujemy przycisk dodawania do listy
		$block.css('display', 'block');
		$block.find('.btn-outline-secondary.btn-to-list').prop('disabled', false);
	}
}

/*
 * szuka selecta parent określonego w "parent-name" bieżącego selecta i zwraca z parenta:
 * A. undefined, jeśli brak listy parent 
 * B. wartość ID listy parent, jeśli ma jakiś wybór
 * C. 0 jeśli brak wyboru na istniejącej liście	parent
 * 
 * @param {object} $select
 */
function valueFromParent($select){
	var id = undefined,
		nameParent = $select.data('parent-name'),
		$parentObj = undefined;

	if(nameParent !== undefined && nameParent.length){
//		console.log('valueFromParent ', nameParent + '_id');
		var $form = $select.closest('form'),
			$parentObj = $form.find('input[name="' + nameParent + '_id"]'); // np hidden country_id dla listy regions
//		console.log('parentObj input', $parentObj);
		if($parentObj === undefined || $parentObj.length === 0){
			$parentObj = $form.find('select[name="' + nameParent + '_id"]'); // np select region_id dla listy subregions
//			console.log('parentObj select', $parentObj);
		}
		if($parentObj !== undefined){
//			console.log('value', $parentObj.val(),  ' from parentObj', $parentObj);
			id = parseInt($parentObj.val(),10) ? parseInt($parentObj.val(),10) : 0;
		}
	}
	
	return id;
}

function typeParent($select){
	var type = undefined,
		nameParent = $select.data('parent-name');

	if(nameParent !== undefined && nameParent.length){
		var $form = $select.closest('form'),
			$parentObj = $form.find('input[name="'+nameParent+'_id"]');
	
		if($parentObj !== undefined){
			type = 'index';
		}else{
			$parentObj = $form.find('select[name="'+nameParent+'_id"]');
			if($parentObj !== undefined){
				type = 'select';
			}
		}
	}
	return type;
}

function setEditing(box){
	if(box !== undefined){
		var $selects = box.find('.select-from-list .custom-select');
		console.log('$selects', $selects);
		$selects.each(function(){
			console.log('value', $(this).data('value').length);
			var v = $(this).data('value'),
				vIs = v.lenght,
				$option = vIs ? $(this).find('option[value=' + v + ']') : null;
			if($option){
				$option.prop('selected', true);
				console.log('Ustawiam select ' + $(this).prop('name') + ': ' + $option.val(), $option.prop('text'));
			}
			resetSelect($(this));

		});		
	}
}


/************* inny sposób na setListOptions z Wishsurfing start *************/

/*
 * wyświetla listę zależną (parent lub child, jeśli są wzajemnie zależne) po zmianie wartości selecta
 * @param {object} $select
 * @param {integer} id
 */
function dependentList($select){
	var id = parseInt($select.val(), 10) ? parseInt($select.val(), 10) : 0;
	var parent = !parseInt($select.data('new-by-dependent'), 10);
	var sdVal = selectDependentValue($select);
	if(sdVal !== undefined){
		var typeDependent = $select.data('dependent-list-type');
		console.log('Sprawdzam listę zależną ' + typeDependent + ' val: ', sdVal);
		var $blockDependent = $select.parents('form').find('.group-select-add.' + typeDependent);
		var $selectDependent = $blockDependent.find('select');
		if(parent){
			if(!id){
				resetSelect($selectDependent);
			}else{
				var action = $select.data('dependent-list');
				if(action !== undefined && $blockDependent !== undefined){
					console.log('Wykonuję ' + action, {id: id, dependent_id: sdVal});
					submitData({id: id, dependent_id: sdVal}, action, $blockDependent);
				}
				$blockDependent.css('display', 'block');
				$selectDependent.prop('selectedIndex', 0);
//				$blockDependent.find('.btn-outline-secondary.btn-to-list').css('display', 'inline-block');
//				$blockDependent.find('.btn-outline-secondary.btn-edit-list').css('display', 'none');
//				$blockDependent.find('.btn-outline-secondary.btn-remove-list').css('display', 'none');
			}
		}
		toggleListButtons($selectDependent);
	}
	toggleListButtons($select);
}

/*
 * zwraca false, jeśli brak listy zależnej lub wartość ID z listy zależnej (0 jeśli brak wyboru)
 */
function selectDependentValue($select){
	var id = undefined;
	var typeDependent = $select.data('dependent-list-type');
	var $selectDependent = typeDependent !== undefined && typeDependent.length 
		? $select.parents('form').find('.group-select-add.' + typeDependent + ' select') 
		: undefined;
	if($selectDependent !== undefined){
		id = parseInt($selectDependent.val(),10) ? parseInt($selectDependent.val(),10) : 0;
	}
	
	return id;
}

/************* inny sposób na setListOptions z Wishsurfing stop *************/
