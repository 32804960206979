/*
 * Css, js i obrazki dla css dodajesz w folderze assets.
 * Aby widzieć zmiany dokonane w css, js i dodanych obrazkach, w konsoli (folder 
 * web) wywołaj obserwację/kompilację: yarn encore dev --watch)
 * Nie zamykaj tego okna konsoli.
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';

const $ = require('jquery');

global.$ = global.jQuery = $;
require('bootstrap');
require('popper.js/dist/popper');
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('bootstrap/js/dist/tooltip');
require('bootstrap/js/dist/popover');
require('./lib/modal');
require('./lib/select');
require('./anywhere');
// require('./sockets'); Wyłączone do odwołania

