/*
 * Trigger must have class='go-modal' and id='any unique id'
 * Modal must have id='modal_' + 'any unique id' from his trigger
 * Modal content must have div with class 'modal-content' and before/after maybe div with:
 *  class 'modal-title'
 */
var $body = $('body'),
	$modals = $body.find('[data-toggle="modal"]');

if ($modals !== undefined) {
	if ($modals.length > 1) {
		console.log('Na stronie jest ' + $modals.length + ' okien modalnych.');
	} else if($modals.length > 0){
		console.log('Na stronie jest jedno okno modalne.');
	}
}
console.log('Aktywna obsługa okien modalnych.\nAktywacja okna z klasą "modal" przez selektor z własnością [data-toggle="modal"].\nOkno modalne musi mieć klasę "modal" oraz id zgodne z [data-target="#id_okna_modalnego"] aktywatora.');

$body.on('click', '.card-header .btn', function(){
	$(this).blur();
});